import { gql } from '@apollo/client';

export const SESSION_DATA_QUERY = gql`
  query SessionDataQuery {
    userByAccountId {
      id
      accountId
      account {
        id
        bannerLogoStorageId
        bannerLogoStorageUrl
        brandingColor
        brandingStatus
        name
        createdAt
      }
      displayName
      email
      measurementSystem
    }
    readAccountByContext {
      id
      branding {
        color
        isActive
        hasSkandWatermark
        reportBannerFileId
        webappBannerFileId
        webappLogoFileId

        reportBannerFile {
          __typename
          ... on ImageFile {
            originalUrl
          }
        }

        webappBannerFile {
          __typename
          ... on ImageFile {
            originalUrl
          }
        }

        webappLogoFile {
          __typename
          ... on ImageFile {
            originalUrl
          }
        }
      }
    }
  }
`;

export const EXTERNAL_SHARE_DATA_QUERY = gql`
  query ExternalShareDataQuery($externalShareId: ID!, $sharedToId: ID!) {
    externalShareByIdAndSharedToId(externalShareId: $externalShareId, sharedToId: $sharedToId) {
      id
      site {
        id
        account {
          id
          brandingStatus
          logoUrl
        }
      }
    }
  }
`;
